<template>
    <div class="card border-primary bg-body">

        <div class="card-body" v-if="!reservation_id">
            <h2>Rezervovat</h2>

            <form>

                <div class="alert alert-danger" v-if="this.errors">
                    <ul class="mb-0">
                        <li v-for="error in this.errors" :key="error" v-html="error[0]"></li>
                    </ul>
                </div>

                <div class="alert alert-warning" v-if="this.warning" v-html="this.warning"></div>

                <div class="row">

                    <div class="form-group col-lg-3">
                        <label for="people">Počet osob</label>
                        <input type="number" min="1" :max="(this.capacity != -1) ? this.capacity : ''" v-model="people" class="form-control">
                    </div>

                    <div class="form-group col-lg-9 mb-4">
                        <label for="date">Datum</label>
                        <select v-model="selected_date" class="form-control" @change="loadTerms" :disabled="!dates.length">
                            <option :value="null" disabled>Vyberte datum...</option>
                            <option v-for="date in dates" :key="date.date" :value="date">{{ date.name }} {{ date.date }}</option>
                        </select>
                    </div>

                    <div class="col-lg-12 mb-4" v-if="terms.length">
                        <label for="term">Zvolte čas návštěvy:</label>
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Čas</th>
                                        <th v-if="capacity != -1">Volná místa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="term in terms" :key="term.id" :class="{'table-danger': !term.capacity}">
                                        <td class="text-center form-check">
                                            <input type="radio" class="form-check-input" v-model="selected_term" :value="term" :disabled="!term.capacity">
                                        </td>
                                        <td>{{ term.formatted_from }} - {{ term.formatted_to }}</td>
                                        <td v-if="capacity != -1">
                                            {{ term.capacity }} osob
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row col-lg-12" v-if="selected_term">
                        <div class="form-group col-lg-6">
                            <label for="name">Jméno a příjmení</label>
                            <input type="text" v-model="name" class="form-control">
                        </div>

                        <div class="form-group col-lg-6">
                            <label for="email">E-mailová adresa</label>
                            <input type="email" v-model="email" class="form-control">
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="phone">Telefonní číslo</label>
                                <input type="tel" v-model="phone" class="form-control">
                            </div>
                        </div>

                        <div class="form-group col-lg-6">
                            <label for="note" class="text-muted">Poznámka (nepovinné)</label>
                            <input type="text" v-model="note" class="form-control">
                        </div>

                        <div class="form-group col-lg-12">
                            <div class="form-check">
                                <input type="checkbox" v-model="gdpr" class="form-check-input">
                                <label class="form-check-label small" for="gdpr">
                                    Souhlasím se <a href="https://www.muzeumjesenik.cz/storage/user-3/module-67/gdpr_recepce-1549291253-16238378850php8208d9.pdf" target="_blank">zpracováním osobních údajů</a>.
                                </label>
                            </div>
                        </div>

                        <vue-recaptcha
                            ref="recaptcha"
                            @verify="captchaVerified"
                            @expired="resetCaptcha"
                            size="invisible"
                            :sitekey="recaptcha_site_key"
                        ></vue-recaptcha>

                        <div class="form-group col-lg-12">
                            <button data-callback="biscolabLaravelReCaptcha" :data-sitekey="recaptcha_site_key"
                                class="btn btn-primary btn-lg w-100 btn-block g-recaptcha" @click="submitReservation"
                                :disabled="!this.canBeSubmitted" type="button">
                                Odeslat rezervaci
                            </button>
                        </div>
                    </div>

                </div>

            </form>
        </div>

        <div class="d-flex justify-content-center min-100-vh w-100" v-else>
            <div class="text-center py-5">
                <h1 class="text-primary">Je to tam!</h1>
                <p class="lead mb-1">Číslo rezervace: <b>{{ reservation_id }}</b></p>
                <p>Budeme se těšit na vaši návštěvu!</p>
            </div>
        </div>
        
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        components: {VueRecaptcha},
        props: ['event_id', 'capacity', 'warning'],
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                recaptcha_site_key: null,
                errors: null,
                reservation_id: null,

                dates: [],
                terms: [],

                people: 1,
                selected_date: null,
                selected_term: null,

                name: null,
                email: null,
                phone: null,
                note: null,
                gdpr: null,
            }
        },
        mounted() {
            this.loadDates();
            this.recaptcha_site_key = process.env.MIX_RECAPTCHA_SITE_KEY;
        },
        computed: {
            canBeSubmitted() {
                return (this.name && this.email && this.phone && this.gdpr && (this.selected_term.capacity >= this.people || !this.capacity));
            },
        },
        methods: {
            loadDates() {
                axios.get('/api/event/' + this.event_id + '/dates')
                    .then(res => this.dates = res.data)
                    .catch(err => alert('Došlo k chybě při načítání dat.'));
            },
            loadTerms() {
                this.selected_term = null;
                axios.get('/api/event/' + this.event_id + '/' + this.selected_date.date + '/terms')
                    .then(res => this.terms = res.data)
                    .catch(err => alert('Došlo k chybě při načítání dat.'));
            },
            submitReservation() {
                this.$refs.recaptcha.execute();
            },
            captchaVerified(token) {
                this.$refs.recaptcha.reset();
                this.errors = null;

                axios.post('/api/event/' + this.event_id, {
                        '_token': this.csrf,
                        'g-recaptcha-response': token,

                        'people': this.people,
                        'date': this.selected_date.date,
                        'term_id': this.selected_term.id,

                        'name': this.name,
                        'email': this.email,
                        'phone': this.phone,
                        'note': this.note,
                        'gdpr': this.gdpr,
                    })
                    .then(res => {
                        this.reservation_id = res.data;
                    })
                    .catch(err => {
                        if (err.response.status == 429)
                            return this.errors = err.response.data.errors;
                        
                        alert('Při odesílání rezervace došlo k chybě. Zkuste to prosím znovu.');
                        console.log(err.response.data.errors);
                    });
            },
            resetCaptcha() {
                this.$refs.recaptcha.reset();
            },
        },
    }
</script>
