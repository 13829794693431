require('./bootstrap');

import Vue from 'vue';

window.Vue = require('vue');

Vue.component('reservation-form', require('./components/ReservationForm.vue').default);

const app = new Vue({
    el: '#app',
});
